<template>
  <v-card class="pa-4 mb-3 messagerie-chat--filter">
    <!-- filtres -->
    <div class="messagerie-chat--filter--header mb-4">
      <v-btn color="success" small @click="createFiche()">
        {{ $t('messagerie.fiche.new_fiche') }}
      </v-btn>
      <div class="d-flex btn-center">
        <v-switch-text :labels="[
          $t('messagerie.filtres.btn_simple'),
          $t('messagerie.filtres.btn_advanced'),
        ]" @input="changeFilterType()" outlined disable-active :value="filterType" as-toggle color="primary"
          small></v-switch-text>
        <v-switch-text v-if="tokenRights && tokenRights.reporting == 1"
          :labels="[$t('menus.hotline'), $t('messagerie.filtres.export')]" @input="changeViewExport()" outlined
          disable-active :value="viewExport" as-toggle color="primary" small></v-switch-text>
      </div>
      <div class="d-flex btn-end">
        <v-btn v-if="tokenRights && tokenRights.gestion_parametre == 1" small color="primary"
          @click="changeViewSettings()">
          <v-icon small color="white">mdi-cog</v-icon>
        </v-btn>

        <v-btn small @click="changeView()" v-if="rightsUser.hotline || rightsUser.detailed_view">
          <v-icon>{{
            detailledView ? 'mdi-fullscreen-exit' : 'mdi-fullscreen'
          }}</v-icon>
        </v-btn>
      </div>
    </div>
    <div v-if="viewExport" class="justify-center d-flex">
      <v-card width="450px" class="text-center justify-center">
        <v-card-title class="d-flex flex-column align-center">{{
          $t('messagerie.filtres.export')
        }}</v-card-title>
        <v-divider></v-divider>

        <!-- proposer de choisir les champs a exporter -->
        <v-card-text class="pa-0 d-flex justify-center align-center">
          <v-checkbox class="ma-0" dense hide-details v-model="isSelectedRows" label="Choisir les colonnes à exporter"></v-checkbox>
        </v-card-text>

        <!-- champs autocomplete proposant sur quoi on souhaite exporter -->
        <v-card-text v-if="isSelectedRows" class="pt-2 pb-0">
          <v-autocomplete class="mb-2" :items="rowsExport" label="Colonnes à exporter" v-model="selectedRows" chips deletable-chips
            multiple solo hide-details>
            <template v-slot:selection="data">
          <v-chip v-if="data.index < 5" :key="data.item" close @click:close="remove(data.item)"> 
            {{ data.item }}
          </v-chip>
          <v-chip v-else-if="data.index === 5" color="primary" close @click:close="remove(data.item)">
            +{{ selectedRows.length - 5 }} autres
          </v-chip>
        </template>
            </v-autocomplete>
        <v-btn @click="selectedRows.length !== rowsExport.length ? selectedRows = rowsExport : selectedRows = []"
          color="primary" small>
          {{ selectedRows.length !== rowsExport.length ? "Tout sélectionner" : "Tout désélectionner" }}
        </v-btn>
        </v-card-text>

        <v-card-text class="pt-2">
          <date-range-picker ref="picker" opens="inline" :locale-data="locale" :ranges="false" singleDatePicker="range"
            :showDropdowns="true" :autoApply="true" v-model="dates_export_range" @update="setDatesExport"
            class="elevation-8 mb-2"></date-range-picker>

          <v-alert dense dark color="red" v-if="dates_export.length < 2">{{
            $t('messagerie.filtres.form.2dates')
          }}</v-alert>
          <div class="d-flex align-center justify-center mb-2" key="duplicate" 
            v-if="dates_export.length > 1 && main_hotline_base && !isSelectedRows"
          > 
            <v-checkbox
              dense
              v-model="duplicate.checked"
              @change="addDoublon(duplicate)"
              label="Inclure les doublons"
            ></v-checkbox>
          </div>
          <v-spacer></v-spacer>
          <v-btn @click="exportStats" color="success" :disabled="dates_export.length < 2">{{
            $t('messagerie.filtres.export') }}</v-btn>
        </v-card-text>
      </v-card>
    </div>
    <!-- Tri simple -->
    <div class="messagerie-chat--filter--content--select d-flex" v-if="!filterType">
      <v-select :items="filters_simple" :label="$t('messagerie.filtres.simple_label')" :value="filter_simple"
        @change="changeFilterSimple" item-value="id">
        <template slot="selection" slot-scope="data">
          {{ $t('messagerie.filtres.simple.' + data.item.cleTraduction) }}
        </template>
        <template slot="item" slot-scope="data">
          {{ $t('messagerie.filtres.simple.' + data.item.cleTraduction) }}
        </template>
      </v-select>

      <!-- filtre fiche par utilisateur affécté à la fiche -->

      <v-autocomplete v-if="main_hotline_base" ref="userSelect" :items="usersObj"
        :item-text="item => `${item.firstname} ${item.lastname}`" :label="'Fiches par utilisateur affecté'"
        @input="changeFilterUsers" item-value="id" v-model="localFiltreUsers">
        <template slot="selection" slot-scope="data">
          {{ data.item.firstname + ' ' + data.item.lastname }}
        </template>
        <template slot="item" slot-scope="data">
          {{ data.item.firstname + ' ' + data.item.lastname }}
        </template>
      </v-autocomplete>

      <!-- filtres fiches par tags -->

      <v-autocomplete v-if="main_hotline_base" ref="tagSelect" v-model="localFiltreTags" item-text="tag.name"
        :items="tagsPlaneteOnline" :label="'Fiches par tags'" @change="changeFilterTags" item-value="_id">
        <template slot="selection" slot-scope="data">
          {{ data.item.tag.name }}
        </template>
        <template slot="item" slot-scope="data">
          {{ data.item.tag.name }}
        </template>
      </v-autocomplete>

      <!-- bouton reset quand filtre selectionné -->
      <v-btn v-if="isFilterVselect && main_hotline_base" color="error" x-small @click="resetFilters">
        <v-icon x-small color="white">mdi-close</v-icon>
      </v-btn>
    </div>
    <!-- Ajouts filtre et liste filtre -->
    <div class="messagerie-chat--filter--content--advanced" v-if="filterType && !filterAdvancedActivate">
      <div class="messagerie-chat--filter--content--advanced--data">
        <v-btn block color="success" dark @click="openFormFilter">
          {{ $t('messagerie.filtres.btn_add_filter') }}
        </v-btn>
        <p></p>
        <div v-if="arrayFilterChecked.length > 0" class="filter--action">
          <v-chip class="my-2" label color="info">
            <v-icon left>
              mdi-filter
            </v-icon>
            {{ arrayFilterChecked.length }}
            {{ $t('messagerie.filtres.filter_advanced_selected') }}
          </v-chip>
          <v-btn elevation="2" color="info" small @click="filterActivate">{{
            $t('messagerie.filtres.filter_advanced_btn_activate') }}
          </v-btn>
          <v-btn elevation="2" color="error" small @click="removeFilters">{{
            $t('actions.delete')
          }}</v-btn>
        </div>
      </div>
      <v-alert color="orange" type="info" v-if="filterUser.length == 0">{{ $t('messagerie.filtres.no_filter') }}
      </v-alert>
      <div class="messagerie-chat--filter--content--advanced--listfilter">
        <div class="filter" v-for="filter in filterUser" :key="filter.id">
          <v-checkbox v-model="filter.checked" @change="filterChecked(filter)"></v-checkbox>
          <p>{{ filter.name }}</p>

          <v-menu top :offset-y="true">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list class="menu-actions-filter">
              <v-list-item-group>
                <v-list-item class="menu-actions-filter--update" @click="updateFilter(filter)">
                  <v-list-item-icon>
                    <v-icon color="orange darken-1">mdi-pencil</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    {{ $t('messagerie.filtres.menu.update') }}
                  </v-list-item-content>
                </v-list-item>
                <v-list-item class="menu-actions-filter--delete" @click="removeFilter(filter)">
                  <v-list-item-icon>
                    <v-icon color="red">mdi-delete</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    {{ $t('messagerie.filtres.menu.delete') }}
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>
        </div>
      </div>
    </div>
    <!-- Si filtres avancé selectionné et actif on affiche -->
    <div v-if="filterType && filterAdvancedActivate">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-chip color="blue" class="mr-2 py-4" small label text-color="white" v-bind="attrs" v-on="on">
            <v-icon left>
              mdi-filter
            </v-icon>
            {{ arrayFilterChecked.length }}
            {{ $t('messagerie.filtres.filter_advanced_selected') }}
          </v-chip>
        </template>
        <ul style="list-style: none;">
          <li v-for="filter in arrayFilterChecked" :key="filter.id">
            {{ filter.name }}
          </li>
        </ul>
      </v-tooltip>

      <v-btn elevation="2" color="info" x-small @click="returnSelectFilter()">{{
        $t('messagerie.filtres.filter_advanced_return') }}
      </v-btn>
    </div>
  </v-card>
</template>

<script>
import HotlineBus from '@/Components/Views/Hotline/HotlineBus';
import MessagerieService from '@/Services/MessagerieService';
import moment from 'moment';
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';

export default {
  name: 'HotlineHeader',
  props: {
    filterType: Boolean,
    viewExport: Boolean,
    tokenRights: Object,
    filter_simple: Object,
    filters_simple: Array,
    filterAdvancedActivate: Boolean,
    arrayFilterChecked: Array,
    filterUser: Array,
    detailledView: Boolean,
    rightsUser: Object,
    bases: Array,
    fichesObj: Array,
    usersObj: Array,
    filtre_users: Array,
    isFilterVselect: Boolean,
    tagsPlaneteOnline: Array,
    main_hotline_base: Boolean,
  },
  components: { DateRangePicker },
  computed: {
    locale() {
      moment.locale(this.$i18n.locale);
      return {
        direction: 'ltr',
        format: this.getLocaleFormat(this.$i18n.locale).toLowerCase(),
        separator: ' → ',
        applyLabel: 'APPLY',
        cancelLabel: 'Cancel',
        weekLabel: 'W',
        customRangeLabel: 'Custom Range',
        daysOfWeek: moment.weekdaysMin(),
        monthNames: moment.monthsShort(),
        firstDay: 1,
        autoApply: true,
      };
    },
  },
  data() {
    return {
      dates_export_range: {},
      dates_export: [],
      filtre_tags: {},
      localFiltreUsers: this.filtre_users,
      localFiltreTags: this.filtre_tags,
      duplicate: {
        checked: false // Initialize the duplicate object
      },
      rowsExport: [
        'Ticket',
        'Client',
        'Demandeur',
        'Etat',
        'Type de demande',
        'Date demande',
        'Module',
        'Details (Module)',
        'Commentaire',
        'Base',
        'Date prévisionnelle',
        'Gestion utilisateur',
        'Suivi',
        'Tag(s)',
        'Date etat terminée',
        'Appel client',
        'Détail d\'appel',
        'Ticket doublon'
      ],
      selectedRows: [],
      isSelectedRows: false,
    };
  },
  watch: {
    localFiltreUsers(newVal) {
      this.$emit('update:filtre_users', newVal);
    },
    localFiltreTags(newVal) {
      this.$emit('update:filtre_tags', newVal);
    },
  },
  methods: {
    setDatesExport() {
      this.dates_export = Array(
        this.dates_export_range.startDate.toLocaleDateString('en-CA'),
        this.dates_export_range.endDate.toLocaleDateString('en-CA')
      );
    },
    resetExportChoice() {
      this.selectedRows = [];
    },
    remove(item) {
      this.selectedRows = this.selectedRows.filter(row => row !== item);
    },
    addDoublon(duplicate) {
      this.duplicate = duplicate;
    },
    async exportStats() {
      if (this.rightsUser.hotline && this.rightsUser.detailed_view) {
        await MessagerieService.exportStats({
          debut: moment(this.dates_export[0]).format('YYYY-MM-DD'),
          fin: moment(this.dates_export[1]).format('YYYY-MM-DD'),
          doublon: this.duplicate.checked,
        }).then((res) => {
          let fileName = 'Export fiches';
          let CSV = '';
          // // si doublon, on ajoute une row pour le ticket doublon a la fin
          if (this.duplicate.checked) {
            let row =
              'Ticket; Client; Demandeur; Etat; Type de demande; Date demande; Module; Details (Module); Commentaire; Base; Date prévisionnelle; Gestion utilisateur; Suivi; Tag(s); Date etat terminée; Appel client; Détail d\'appel; Ticket doublon;';
            CSV += row + '\r\n';
            res.forEach((fiche) => {
                CSV += fiche.id.replace(/[\r\n;]+/g, ' ') + ';';
                CSV += fiche.client.replace(/[\r\n;]+/g, ' ') + ';';
                CSV += fiche.demandeur.replace(/[\r\n;]+/g, ' ') + ';';
                CSV +=
                    this.$t('messagerie.parametrages.' + fiche.etat).replace(
                        /[\r\n;]+/g,
                        ' '
                    ) + ';';
                CSV +=
                    this.$t('messagerie.parametrages.' + fiche.type).replace(
                        /[\r\n;]+/g,
                        ' '
                    ) + ';';
                CSV += fiche.cree.substr(0, 10).replace(/[\r\n;]+/g, ' ') + ';';
                let module = fiche.module;
                if (fiche.sous_module) {
                    module += ' | ' + fiche.sous_module.replace(/[\r\n;]+/g, ' ');
                }
                CSV += module.replace(/[\r\n;]+/g, ' ') + ';';
                CSV += fiche.module_details.replace(/[\r\n;]+/g, ' ') + ';';
                CSV += fiche.comment.replace(/[\r\n;]+/g, ' ') + ';';
                CSV += fiche.base.replace(/[\r\n;]+/g, ' ') + ';';
                // si date previsionnelle vide, on met rien
                CSV += fiche.date_previsionnel ?
                    fiche.date_previsionnel.replace(/[\r\n;]+/g, ' ') + ';' :
                    ';';
                CSV += fiche.users ?
                    fiche.users.replace(/[\r\n;]+/g, ' ') + ';' :
                    ';';
                CSV += fiche.suivi ?
                    fiche.suivi.replace(/[\r\n;]+/g, ' ') + ';' :
                    ';';
                CSV += fiche.tags + ';'; 
                // afficher que la date de l'état terminée si l'état est terminé sinon rien
                CSV += fiche.etat == 'etat.demande_terminee' ?
                    fiche.date_changement_etat + ';' :
                    ';';
                // Vérifie s'il y a des appels clients
                CSV += fiche.appel_client && Array.isArray(fiche.appel_client) && fiche.appel_client.length > 0 ?
                    fiche.appel_client[fiche.appel_client.length - 1].details + ';' :
                    'Non;';

                let infosAppels = fiche.appel_client && Array.isArray(fiche.appel_client) ?
                    fiche.appel_client.map(appel => `${appel.user} - ${appel.date} - ${appel.details}`).join(', ') :
                    '';
                CSV += infosAppels + ';';
                // si doublon, on ajoute une colonne pour le ticket doublon
                CSV += fiche.doublon ? fiche.doublon : '';

                CSV += '\r\n';
            });

          } 
          // composer le CSV pour les fiches avec le select des colonnes

          else if (this.selectedRows.length > 0) {
            let row = '';
            this.selectedRows.forEach((selectedRow) => {
              row += selectedRow + ';';
            });
            CSV += row + '\r\n';
            res.forEach((fiche) => {
              let row = '';
              this.selectedRows.forEach((selectedRow) => {
                switch (selectedRow) {
                  case 'Ticket': {
                    row += fiche.id.replace(/[\r\n;]+/g, ' ') + ';';
                    break;
                  }
                  case 'Client': {
                    row += fiche.client.replace(/[\r\n;]+/g, ' ') + ';';
                    break;
                  }
                  case 'Demandeur': {
                    row += fiche.demandeur.replace(/[\r\n;]+/g, ' ') + ';';
                    break;
                  }
                  case 'Etat': {
                    row += this.$t('messagerie.parametrages.' + fiche.etat).replace(/[\r\n;]+/g, ' ') + ';';
                    break;
                  }
                  case 'Type de demande': {
                    row += this.$t('messagerie.parametrages.' + fiche.type).replace(/[\r\n;]+/g, ' ') + ';';
                    break;
                  }
                  case 'Date demande': {
                    row += fiche.cree.substr(0, 10).replace(/[\r\n;]+/g, ' ') + ';';
                    break;
                  }
                  case 'Module': {
                    let module = fiche.module;
                    if (fiche.sous_module) {
                      module += ' | ' + fiche.sous_module.replace(/[\r\n;]+/g, ' ');
                    }
                    row += module.replace(/[\r\n;]+/g, ' ') + ';';
                    break;
                  }
                  case 'Details (Module)': {
                    row += fiche.module_details.replace(/[\r\n;]+/g, ' ') + ';';
                    break;
                  }
                  case 'Commentaire': {
                    row += fiche.comment.replace(/[\r\n;]+/g, ' ') + ';';
                    break;
                  }
                  case 'Base': {
                    row += fiche.base.replace(/[\r\n;]+/g, ' ') + ';';
                    break;
                  }
                  case 'Date prévisionnelle': {
                    row += fiche.date_previsionnel ?
                      fiche.date_previsionnel.replace(/[\r\n;]+/g, ' ') + ';' :
                      ';';
                    break;
                  }
                  case 'Gestion utilisateur': {
                    row += fiche.users ?
                      fiche.users.replace(/[\r\n;]+/g, ' ') + ';' :
                      ';';
                    break;
                  }
                  case 'Suivi': {
                    row += fiche.suivi ?
                      fiche.suivi.replace(/[\r\n;]+/g, ' ') + ';' :
                      ';';
                    break;
                  }
                  case 'Tag(s)': {
                    row += fiche.tags + ';';
                    break;
                  }
                  case 'Date etat terminée': {
                    row += fiche.etat == 'etat.demande_terminee' ?
                      fiche.date_changement_etat + ';' :
                      ';';
                    break;
                  }
                  case 'Appel client': {
                    row += fiche.appel_client && Array.isArray(fiche.appel_client) && fiche.appel_client.length > 0 ?
                      fiche.appel_client[fiche.appel_client.length - 1].details + ';' :
                      'Non;';
                    break;
                  }
                  case 'Détail d\'appel': {
                    let infosAppels = fiche.appel_client && Array.isArray(fiche.appel_client) ?
                      fiche.appel_client.map(appel => `${appel.user} - ${appel.date} - ${appel.details}`).join(', ') :
                      '';
                    row += infosAppels + ';';
                    break;
                  }
                  case 'Ticket doublon': {
                    row += fiche.doublon ? fiche.doublon : '';
                    break;
                  }
                }
              });
              CSV += row + '\r\n';
            });
          } else {
            let row =
              'Ticket; Client; Demandeur; Etat; Type de demande; Date demande; Module; Details (Module); Commentaire; Base; Date prévisionnelle; Gestion utilisateur; Suivi; Tag(s); Date etat terminée; Appel client; Détail d\'appel;';
            CSV += row + '\r\n';
            res.forEach((fiche) => {
              CSV += fiche.id.replace(/[\r\n;]+/g, ' ') + ';';
              CSV += fiche.client.replace(/[\r\n;]+/g, ' ') + ';';
              CSV += fiche.demandeur.replace(/[\r\n;]+/g, ' ') + ';';
              CSV +=
                this.$t('messagerie.parametrages.' + fiche.etat).replace(
                  /[\r\n;]+/g,
                  ' '
                ) + ';';
              CSV +=
                this.$t('messagerie.parametrages.' + fiche.type).replace(
                  /[\r\n;]+/g,
                  ' '
                ) + ';';
              CSV += fiche.cree.substr(0, 10).replace(/[\r\n;]+/g, ' ') + ';';
              let module = fiche.module;
              if (fiche.sous_module) {
                module += ' | ' + fiche.sous_module.replace(/[\r\n;]+/g, ' ');
              }
              CSV += module.replace(/[\r\n;]+/g, ' ') + ';';
              CSV += fiche.module_details.replace(/[\r\n;]+/g, ' ') + ';';
              CSV += fiche.comment.replace(/[\r\n;]+/g, ' ') + ';';
              CSV += fiche.base.replace(/[\r\n;]+/g, ' ') + ';';
              // si date previsionnelle vide, on met rien
              CSV += fiche.date_previsionnel ?
                fiche.date_previsionnel.replace(/[\r\n;]+/g, ' ') + ';' :
                ';';
              CSV += fiche.users ?
                fiche.users.replace(/[\r\n;]+/g, ' ') + ';' :
                ';';
              CSV += fiche.suivi ?
                fiche.suivi.replace(/[\r\n;]+/g, ' ') + ';' :
                ';';
              CSV += fiche.tags + ';';
              // afficher que la date de l'état terminée si l'état est terminé sinon rien
              CSV += fiche.etat == 'etat.demande_terminee' ?
                fiche.date_changement_etat + ';' :
                ';';
              // Vérifie s'il y a des appels clients
              CSV += fiche.appel_client && Array.isArray(fiche.appel_client) && fiche.appel_client.length > 0 ?
                fiche.appel_client[fiche.appel_client.length - 1].details + ';' :
                'Non;;';

              let infosAppels = fiche.appel_client && Array.isArray(fiche.appel_client) ?
                fiche.appel_client.map(appel => `${appel.user} - ${appel.date} - ${appel.details}`).join(', ') :
                '';
              CSV += infosAppels + ';';

              CSV += '\r\n';
            });

          }
          let uri = 'data:text/csv;charset=utf-8,' + escape(CSV);

          // Now the little tricky part.
          // you can use either>> window.open(uri);
          // but this will not work in some browsers
          // or you will not get the correct file extension

          //this trick will generate a temp <a /> tag
          let link = document.createElement('a');
          link.href = uri;

          //set the visibility hidden so it will not effect on your web-layout
          link.style = 'visibility:hidden';
          link.download = fileName + '.csv';

          //this part will append the anchor tag and remove it after automatic click
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        });
      } else {
        await MessagerieService.exportStats({
          debut: moment(this.dates_export[0]).format('YYYY-MM-DD'),
          fin: moment(this.dates_export[1]).format('YYYY-MM-DD'),
          bases: this.bases,
        }).then((res) => {
          let CSV = '';

          let row =
            'Ticket; Client; Demandeur; Etat; Type de demande; Date demande; Module; Details (Module); Commentaire; Base;  Date prévisionnelle;';
          CSV += row + '\r\n';
          let fileName = 'Export fiches';
          res.forEach((fiche) => {
            CSV += fiche.id.replace(/[\r\n;]+/g, ' ') + ';';
            CSV += fiche.client.replace(/[\r\n;]+/g, ' ') + ';';
            CSV += fiche.demandeur.replace(/[\r\n;]+/g, ' ') + ';';
            CSV +=
              this.$t('messagerie.parametrages.' + fiche.etat).replace(
                /[\r\n;]+/g,
                ' '
              ) + ';';
            CSV +=
              this.$t('messagerie.parametrages.' + fiche.type).replace(
                /[\r\n;]+/g,
                ' '
              ) + ';';
            CSV += fiche.cree.substr(0, 10).replace(/[\r\n;]+/g, ' ') + ';';
            let module = fiche.module;
            if (fiche.sous_module) {
              module += ' | ' + fiche.sous_module.replace(/[\r\n;]+/g, ' ');
            }
            CSV += module.replace(/[\r\n;]+/g, ' ') + ';';
            CSV += fiche.module_details.replace(/[\r\n;]+/g, ' ') + ';';
            CSV += fiche.comment.replace(/[\r\n;]+/g, ' ') + ';';
            CSV += fiche.base.replace(/[\r\n;]+/g, ' ') + ';';
            // si date previsionnelle vide, on met rien
            CSV += fiche.date_previsionnelle
              ? fiche.date_previsionnelle.replace(/[\r\n;]+/g, ' ') + ';'
              : ';';
            CSV += '\r\n';
          });

          let uri = 'data:text/csv;charset=utf-8,' + escape(CSV);

          // Now the little tricky part.
          // you can use either>> window.open(uri);
          // but this will not work in some browsers
          // or you will not get the correct file extension

          //this trick will generate a temp <a /> tag
          let link = document.createElement('a');
          link.href = uri;

          //set the visibility hidden so it will not effect on your web-layout
          link.style = 'visibility:hidden';
          link.download = fileName + '.csv';

          //this part will append the anchor tag and remove it after automatic click
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        });
      }
      this.resetExportChoice();
      this.isSelectedRows = false;
    },
    removeFilters() {
      HotlineBus.$emit('MessagerieHeader-filter-remove');
    },
    createFiche() {
      HotlineBus.$emit('MessagerieHeader-create-fiche');
    },
    changeView() {
      HotlineBus.$emit('MessagerieHeader-change-view', !this.detailledView);
    },
    changeFilterType() {
      HotlineBus.$emit('MessagerieHeader-change-filter-type');
    },
    changeViewExport() {
      HotlineBus.$emit('MessagerieHeader-change-view-export');
    },
    changeViewSettings() {
      HotlineBus.$emit('MessagerieHeader-change-view-settings');
    },
    openFormFilter() {
      HotlineBus.$emit('MessagerieHeader-open-form-filter');
    },
    changeFilterSimple(filter_simple) {
      HotlineBus.$emit('MessagerieHeader-change-filter-simple', {
        id: filter_simple,
      });
    },
    changeFilterUsers(filtre_users) {
      for (let i in this.usersObj) {
        if (this.usersObj[i].id == filtre_users) {
          HotlineBus.$emit(
            'MessagerieHeader-change-filter-users',
            this.usersObj[i]
          );
        }
      }
    },
    changeFilterTags(filtre_tags) {
      this.tagsPlaneteOnline.forEach((tag) => {
        if (tag._id == filtre_tags) {
          filtre_tags = tag;
        }
      });
      let obj = {
        tag: filtre_tags.tag.name,
        id: filtre_tags._id,
      };
      HotlineBus.$emit('MessagerieHeader-change-filter-tags', obj);
    },
    resetFilters() {
      this.$refs.userSelect.reset();
      this.$refs.tagSelect.reset();
      HotlineBus.$emit('MessagerieHeader-change-reset-filter-user');
    },
    filterActivate() {
      HotlineBus.$emit('MessagerieHeader-filter-activate');
    },
    filterChecked(filter) {
      HotlineBus.$emit('MessagerieHeader-filter-checked', filter);
    },
    returnSelectFilter() {
      HotlineBus.$emit('MessagerieHeader-return-select');
    },
    updateFilter(filter) {
      HotlineBus.$emit('MessagerieHeader-update-filter', filter);
    },
    removeFilter(filter) {
      HotlineBus.$emit('MessagerieHeader-remove-filter', filter);
    },
  },
  mounted() {
  },
};
</script>

<style lang="scss" scoped>
.vue-daterange-picker {
  color: #000000;
}

.messagerie-chat--filter--content--select {
  gap: 1rem;
  align-items: center;
}

.messagerie-chat--filter--header {
  align-items: center;
}

.btn-center {
  gap: 0.2rem;
}

.btn-end {
  gap: 0.2rem;
}
</style>
